<template>
  <form-field-text
    input-type="number"
    single-line
    :label="hideLabel ? undefined : label"
    :value="price"
    v-bind="$attrs"
    @input:debounce="onInputDebounce"
    @save="onSave"
  >
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
  </form-field-text>
</template>

<script lang="ts">
import { Component, Mixins, Prop, VModel } from "vue-property-decorator";
import TaxTypeCacheMixin from "@/modules/taxtypes/mixins/TaxTypeCacheMixin";
import { AppModule } from "@/store/app";
import type { CompanySettingsData } from "@planetadeleste/vue-mc-gw";

@Component
export default class PriceField extends Mixins(TaxTypeCacheMixin) {
  @VModel({ type: [String, Number] }) price!: number;
  @Prop({ type: String, default: "price" }) readonly label!: string;
  @Prop(Boolean) readonly hideLabel!: boolean;

  get company() {
    return AppModule.company;
  }

  get settings(): Partial<CompanySettingsData> {
    return this.company.get("settings", {});
  }

  onInputDebounce(sValue: number) {
    this.$emit("input:debounce", sValue);
  }

  onSave(sValue: number) {
    this.$emit("save", sValue);
  }
}
</script>
